import { useMutation } from '@apollo/client';

const useDownloadPdfClientFile = (queryDocument) => {
  const [fetchPdf] = useMutation(queryDocument);

  const generateNewPdf = async (masterEntityId) => {
    const { data: responseData } = await fetchPdf({
      variables: { masterEntityId },
    });

    return responseData || null;
  };

  return [generateNewPdf];
};

export default useDownloadPdfClientFile;
