import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useGetCountryFromUrl from './useGetCountryFromUrl';
// import { NegativeSurplusAlertButton } from '../components/buttons';

const useShoppingCartSummary = (shoppingCartData) => {
  const { t } = useTranslation();
  const country = useGetCountryFromUrl();
  const formatedShoppingCart = useMemo(() => {
    const shoppingCartArray = [
      {
        title: 'Cantidad de facturas',
        subtitle: '',
        value: shoppingCartData?.invoiceCount,
        money: false,
      },
      {
        title: 'Monto total',
        subtitle: '',
        value: shoppingCartData?.totalAmount,
        money: true,
      },
      {
        title: t('Price diference'),
        subtitle: '',
        value: shoppingCartData?.priceDifference,
        money: true,
      },
      {
        title: 'Tasa',
        subtitle: '',
        value: shoppingCartData?.rate,
        money: false,
      },
      {
        title: t('retention'),
        subtitle: '',
        value: shoppingCartData?.retainedAmount,
        money: true,
      },
      {
        title: 'Monto a depositar',
        subtitle: 'En base a simulación a 30 días.',
        value: shoppingCartData?.depositAmount,
        money: true,
        color: 'primary.main',
        weight: 'bold',
      },
    ];
    if (shoppingCartData?.orderingDebt > 0) {
      shoppingCartArray.push({
        title: 'Descuento de Ordering',
        value: (shoppingCartData?.orderingDebt || 0) * -1,
        money: true,
      });
    }
    if (shoppingCartData?.negativeSurplus?.amount > 0) {
      shoppingCartArray.splice(7, 0, {
        title: 'Cobro por mora acumulada',
        value: shoppingCartData?.negativeSurplus,
        money: true,
        // icon: <NegativeSurplusAlertButton />,
      });
    }
    if (country?.name === 'Chile') {
      shoppingCartArray.splice(4, 0, {
        title: 'Comisión',
        subtitle: '',
        value: shoppingCartData?.commission,
        money: true,
      });
      shoppingCartArray.splice(5, 0, {
        title: 'Costos totales',
        subtitle: '',
        value: shoppingCartData?.totalCosts,
        money: true,
      });
    }
    return shoppingCartArray;
  }, [shoppingCartData]);
  return formatedShoppingCart;
};

export default useShoppingCartSummary;
